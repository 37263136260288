import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import get from 'lodash/get'

class CategoryNav extends React.Component {
    render(props) {
        const categories = get(this, 'props.data');


        return (

        <Nav>
            <li><Link to="/tips/" activeClassName="active">All</Link></li>
            {categories.map(({ node }) => {
                return (
                    <li key={node.slug}>
                        <Link to={`/tips/category/${node.slug}/`} activeClassName="active">{node.name}</Link>
                    </li>
                )
            })}
        </Nav>

        )
    }
}

export default CategoryNav

const Nav = styled.ul`
    margin: 0;
    padding: 0;
    font-size: 16px;
    list-style: none;
    display: flex;
    justify-content: space-around;
    
    @media(max-width: 768px) {
        flex-direction: column;
        
        li {
            margin-bottom: 10px;
        }
    }
    
    a {
        padding: 8px 18px;
        border: 1px solid;
        border-radius: 5px;
        display: flex;
        
        &:hover,
        &.active {
            background: #3c7db6;
            color: white;
        }
    }

`;